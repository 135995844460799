import { Routes } from '@angular/router';
import { CrmAuthGuard } from 'common-module/auth';
import { CrmLayoutV2OutletComponent } from 'common-module/layout-v2';

export enum AppRoutes {
  'profile' = 'profile',
  'dashboard' = 'dashboard',
  'users' = 'users',
  'segments' = 'segments',
  'transactions' = 'transactions',
  'benefits' = 'benefits',
  'reports' = 'reports',
}

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('common-module/auth').then((a) => a.authRoutes),
  },
  {
    path: '',
    component: CrmLayoutV2OutletComponent,
    canActivate: [CrmAuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'users/clients',
      },
      {
        path: AppRoutes.users,
        loadChildren: () =>
          import('./features/users/users.routes').then(({ routes }) => routes),
      },
      {
        path: AppRoutes.segments,
        loadChildren: () =>
          import('./features/segments/segments.routes').then(
            ({ routes }) => routes,
          ),
      },
      {
        path: AppRoutes.transactions,
        loadChildren: () =>
          import('./features/transactions/transactions.routes').then(
            ({ routes }) => routes,
          ),
      },
      {
        path: AppRoutes.benefits,
        loadChildren: () =>
          import('./features/benefits/benefits.routes').then(
            ({ routes }) => routes,
          ),
      },
      {
        path: AppRoutes.reports,
        loadChildren: () =>
          import('./features/reports/reports.routes').then(
            ({ routes }) => routes,
          ),
      },
      {
        path: AppRoutes.dashboard,
        loadChildren: () =>
          import('./features/dashboard/dashboard.routes').then(
            ({ routes }) => routes,
          ),
      },
      {
        path: AppRoutes.profile,
        loadChildren: () =>
          import('./features/profile/profile.routes').then(
            ({ routes }) => routes,
          ),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/',
  },
];
