import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import * as i0 from '@angular/core';
import { EventEmitter, signal, inject, ElementRef, Component, ChangeDetectionStrategy, Input, HostBinding, Output, HostListener } from '@angular/core';
import { CrmLoadingComponent } from 'common-module/common';
import { CrmUnsubscribeDirective } from 'common-module/core';
import { CrmTranslatePipe } from 'common-module/translate';
import { NzAvatarComponent } from 'ng-zorro-antd/avatar';
import { NzIconDirective } from 'ng-zorro-antd/icon';
import { NzMenuDirective, NzMenuItemComponent } from 'ng-zorro-antd/menu';
import { Subject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
const _forTrack0 = ($index, $item) => $item.key;
function CrmUserProfileComponent_Conditional_0_Conditional_1_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r0.profile.role, " ");
  }
}
function CrmUserProfileComponent_Conditional_0_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2)(1, "div", 3);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(3, CrmUserProfileComponent_Conditional_0_Conditional_1_Conditional_3_Template, 2, 1, "div", 4);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "div", 5);
    i0.ɵɵelement(5, "i", 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", ctx_r0.profile.name, " ");
    i0.ɵɵadvance();
    i0.ɵɵconditional(3, ctx_r0.profile.role ? 3 : -1);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("nzType", ctx_r0.isDropdownVisible() ? ctx_r0.dropdownIconVisible : ctx_r0.dropdownIcon);
  }
}
function CrmUserProfileComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "nz-avatar", 1);
    i0.ɵɵtemplate(1, CrmUserProfileComponent_Conditional_0_Conditional_1_Template, 6, 3);
  }
  if (rf & 2) {
    let tmp_3_0;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("nzSrc", ctx_r0.profile.avatar)("nzIcon", ctx_r0.avatarConfig == null ? null : ctx_r0.avatarConfig.icon)("nzSize", (tmp_3_0 = ctx_r0.compact ? ctx_r0.avatarConfig == null ? null : ctx_r0.avatarConfig.size == null ? null : ctx_r0.avatarConfig.size.compact : ctx_r0.avatarConfig == null ? null : ctx_r0.avatarConfig.size == null ? null : ctx_r0.avatarConfig.size.base) !== null && tmp_3_0 !== undefined ? tmp_3_0 : "default");
    i0.ɵɵadvance();
    i0.ɵɵconditional(1, !ctx_r0.compact ? 1 : -1);
  }
}
function CrmUserProfileComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "crm-loading", 7);
  }
}
function CrmUserProfileComponent_ng_template_2_For_2_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "i", 11);
  }
  if (rf & 2) {
    const item_r3 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("nzType", item_r3.icon);
  }
}
function CrmUserProfileComponent_ng_template_2_For_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "li", 10);
    i0.ɵɵlistener("click", function CrmUserProfileComponent_ng_template_2_For_2_Template_li_click_0_listener($event) {
      const item_r3 = i0.ɵɵrestoreView(_r2).$implicit;
      const ctx_r0 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r0.handleItemClicked($event, item_r3));
    });
    i0.ɵɵtemplate(1, CrmUserProfileComponent_ng_template_2_For_2_Conditional_1_Template, 1, 1, "i", 11);
    i0.ɵɵelementStart(2, "span", 12);
    i0.ɵɵtext(3);
    i0.ɵɵpipe(4, "crmTranslate");
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    let tmp_13_0;
    const item_r3 = ctx.$implicit;
    i0.ɵɵclassMapInterpolate1("crm-user-profile-item crm-user-profile-item-", item_r3.key, "");
    i0.ɵɵadvance();
    i0.ɵɵconditional(1, item_r3.icon ? 1 : -1);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(4, 5, (tmp_13_0 = item_r3.title) !== null && tmp_13_0 !== undefined ? tmp_13_0 : item_r3.key), " ");
  }
}
function CrmUserProfileComponent_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "ul", 8);
    i0.ɵɵrepeaterCreate(1, CrmUserProfileComponent_ng_template_2_For_2_Template, 5, 7, "li", 9, _forTrack0);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("nzTheme", ctx_r0.dark ? "dark" : "light");
    i0.ɵɵadvance();
    i0.ɵɵrepeater(ctx_r0.items);
  }
}
class CrmUserProfileComponent extends CrmUnsubscribeDirective {
  constructor() {
    super(...arguments);
    this.items = [];
    this.dropdownIcon = 'more';
    this.dropdownIconVisible = 'more';
    this.dropdownTrigger = 'hover';
    this.dropdownPlacement = 'top';
    this.compact = false;
    this.dark = false;
    this.itemClicked$ = new EventEmitter();
    this.hostClass = 'crm-user-profile';
    this.isDropdownVisible = signal(false);
    this.elementRef = inject(ElementRef);
    this.overlaySettings = {
      width: 'auto',
      offsetX: 0,
      offsetY: 0
    };
    this.destroyCheckLeave$ = new Subject();
  }
  get clickTriggerClass() {
    return this.dropdownTrigger === 'click';
  }
  onMouseEnter() {
    if (this.dropdownTrigger === 'hover' && !this.isDropdownVisible()) {
      this.resolveOverlaySettings();
      this.isDropdownVisible.set(true);
      this.checkLeave();
    }
  }
  onClick() {
    if (this.dropdownTrigger === 'click' && !this.isDropdownVisible()) {
      this.resolveOverlaySettings();
      this.isDropdownVisible.set(true);
    }
  }
  ngOnInit() {
    this.avatarConfig ??= {
      icon: 'user',
      size: {
        base: 'large',
        compact: 'small'
      }
    };
  }
  handleItemClicked(event, item) {
    this.isDropdownVisible.set(false);
    this.destroyCheckLeave$.next(true);
    this.itemClicked$.next({
      event,
      item
    });
  }
  checkLeave() {
    fromEvent(document, 'mousemove').pipe(takeUntil(this.destroyCheckLeave$), takeUntil(this.destroyed$), debounceTime(100)).subscribe(event => {
      const element = event.target;
      const classes = (element?.className ?? '').split(' ');
      if (classes.some(c => c.startsWith('crm-user-profile'))) {
        return;
      }
      this.isDropdownVisible.set(false);
      this.destroyCheckLeave$.next(true);
    });
  }
  resolveOverlaySettings() {
    const width = ['top', 'bottom'].includes(this.dropdownPlacement) ? `${this.elementRef.nativeElement.offsetWidth}px` : 'auto';
    const offsetX = this.dropdownPlacement === 'right' ? this.elementRef.nativeElement.offsetWidth : 0;
    const offsetY = this.dropdownPlacement === 'right' ? this.elementRef.nativeElement.offsetHeight : 0;
    this.overlaySettings = {
      width,
      offsetX,
      offsetY
    };
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCrmUserProfileComponent_BaseFactory;
      return function CrmUserProfileComponent_Factory(t) {
        return (ɵCrmUserProfileComponent_BaseFactory || (ɵCrmUserProfileComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CrmUserProfileComponent)))(t || CrmUserProfileComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CrmUserProfileComponent,
      selectors: [["crm-user-profile"]],
      hostVars: 8,
      hostBindings: function CrmUserProfileComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("mouseenter", function CrmUserProfileComponent_mouseenter_HostBindingHandler() {
            return ctx.onMouseEnter();
          })("click", function CrmUserProfileComponent_click_HostBindingHandler() {
            return ctx.onClick();
          });
        }
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClass);
          i0.ɵɵclassProp("crm-user-profile-compact", ctx.compact)("crm-user-profile-dark", ctx.dark)("crm-user-profile-click-trigger", ctx.clickTriggerClass);
        }
      },
      inputs: {
        profile: "profile",
        items: "items",
        dropdownIcon: "dropdownIcon",
        dropdownIconVisible: "dropdownIconVisible",
        dropdownTrigger: "dropdownTrigger",
        dropdownPlacement: "dropdownPlacement",
        avatarConfig: "avatarConfig",
        compact: "compact",
        dark: "dark"
      },
      outputs: {
        itemClicked$: "itemClicked$"
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 3,
      vars: 8,
      consts: [["cdkConnectedOverlay", "", 3, "backdropClick", "cdkConnectedOverlayOrigin", "cdkConnectedOverlayMinWidth", "cdkConnectedOverlayOpen", "cdkConnectedOverlayHasBackdrop", "cdkConnectedOverlayOffsetX", "cdkConnectedOverlayOffsetY", "cdkConnectedOverlayPanelClass"], [1, "crm-user-profile-avatar", 3, "nzSrc", "nzIcon", "nzSize"], [1, "crm-user-profile-content"], [1, "crm-user-profile-content-name"], [1, "crm-user-profile-content-role"], [1, "crm-user-profile-dropdown-icon"], ["nz-icon", "", 3, "nzType"], ["size", "small"], ["nz-menu", "", 1, "crm-user-profile-items", 3, "nzTheme"], ["nz-menu-item", "", 3, "class"], ["nz-menu-item", "", 3, "click"], ["nz-icon", "", 1, "crm-user-profile-item-icon", 3, "nzType"], [1, "crm-user-profile-item-title"]],
      template: function CrmUserProfileComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, CrmUserProfileComponent_Conditional_0_Template, 2, 4)(1, CrmUserProfileComponent_Conditional_1_Template, 1, 0)(2, CrmUserProfileComponent_ng_template_2_Template, 3, 1, "ng-template", 0);
          i0.ɵɵlistener("backdropClick", function CrmUserProfileComponent_Template_ng_template_backdropClick_2_listener() {
            return ctx.isDropdownVisible.set(false);
          });
        }
        if (rf & 2) {
          i0.ɵɵconditional(0, ctx.profile ? 0 : 1);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("cdkConnectedOverlayOrigin", ctx.elementRef)("cdkConnectedOverlayMinWidth", ctx.overlaySettings.width)("cdkConnectedOverlayOpen", ctx.isDropdownVisible())("cdkConnectedOverlayHasBackdrop", ctx.dropdownTrigger === "click")("cdkConnectedOverlayOffsetX", ctx.overlaySettings.offsetX)("cdkConnectedOverlayOffsetY", ctx.overlaySettings.offsetY)("cdkConnectedOverlayPanelClass", ctx.dark ? "crm-user-profile-dropdown-dark" : "crm-user-profile-dropdown");
        }
      },
      dependencies: [CrmTranslatePipe, CdkConnectedOverlay, NzIconDirective, NzAvatarComponent, CrmLoadingComponent, NzMenuDirective, NzMenuItemComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmUserProfileComponent, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'crm-user-profile',
      template: `
    @if (profile) {
      <nz-avatar
        class="crm-user-profile-avatar"
        [nzSrc]="profile.avatar"
        [nzIcon]="avatarConfig?.icon"
        [nzSize]="
          (compact ? avatarConfig?.size?.compact : avatarConfig?.size?.base) ??
          'default'
        "
      ></nz-avatar>

      @if (!compact) {
        <div class="crm-user-profile-content">
          <div class="crm-user-profile-content-name">
            {{ profile.name }}
          </div>
          @if (profile.role) {
            <div class="crm-user-profile-content-role">
              {{ profile.role }}
            </div>
          }
        </div>
        <div class="crm-user-profile-dropdown-icon">
          <i
            nz-icon
            [nzType]="isDropdownVisible() ? dropdownIconVisible : dropdownIcon"
          ></i>
        </div>
      }
    } @else {
      <crm-loading size="small"></crm-loading>
    }

    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="elementRef"
      [cdkConnectedOverlayMinWidth]="overlaySettings.width"
      [cdkConnectedOverlayOpen]="isDropdownVisible()"
      [cdkConnectedOverlayHasBackdrop]="dropdownTrigger === 'click'"
      [cdkConnectedOverlayOffsetX]="overlaySettings.offsetX"
      [cdkConnectedOverlayOffsetY]="overlaySettings.offsetY"
      [cdkConnectedOverlayPanelClass]="
        dark ? 'crm-user-profile-dropdown-dark' : 'crm-user-profile-dropdown'
      "
      (backdropClick)="isDropdownVisible.set(false)"
    >
      <ul
        class="crm-user-profile-items"
        [nzTheme]="dark ? 'dark' : 'light'"
        nz-menu
      >
        @for (item of items; track item.key) {
          <li
            class="crm-user-profile-item crm-user-profile-item-{{ item.key }}"
            nz-menu-item
            (click)="handleItemClicked($event, item)"
          >
            @if (item.icon) {
              <i
                class="crm-user-profile-item-icon"
                nz-icon
                [nzType]="item.icon"
              ></i>
            }

            <span class="crm-user-profile-item-title">
              {{ item.title ?? item.key | crmTranslate }}
            </span>
          </li>
        }
      </ul>
    </ng-template>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [CrmTranslatePipe, CdkConnectedOverlay, NzIconDirective, NzAvatarComponent, CrmLoadingComponent, NzMenuDirective, NzMenuItemComponent]
    }]
  }], null, {
    profile: [{
      type: Input
    }],
    items: [{
      type: Input
    }],
    dropdownIcon: [{
      type: Input
    }],
    dropdownIconVisible: [{
      type: Input
    }],
    dropdownTrigger: [{
      type: Input
    }],
    dropdownPlacement: [{
      type: Input
    }],
    avatarConfig: [{
      type: Input
    }],
    compact: [{
      type: HostBinding,
      args: ['class.crm-user-profile-compact']
    }, {
      type: Input
    }],
    dark: [{
      type: HostBinding,
      args: ['class.crm-user-profile-dark']
    }, {
      type: Input
    }],
    itemClicked$: [{
      type: Output
    }],
    hostClass: [{
      type: HostBinding,
      args: ['class']
    }],
    clickTriggerClass: [{
      type: HostBinding,
      args: ['class.crm-user-profile-click-trigger']
    }],
    onMouseEnter: [{
      type: HostListener,
      args: ['mouseenter']
    }],
    onClick: [{
      type: HostListener,
      args: ['click']
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CrmUserProfileComponent };
